<template>
  <div>
  
    <div>
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="7" lg="12" md="12">
          <user-view-user-info-card />
        </b-col>
        <b-col cols="12" md="12" xl="5" lg="12">
          <b-card title="Change Password">
            <change-password-component />
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6"> </b-col>
        <b-col cols="12" lg="12">
          <!-- <user-view-user-permissions-card /> -->
        </b-col>
      </b-row>

      <!-- <invoice-list /> -->
    </div>
  </div>
</template>

<script>
import {
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCard,
  BCardText,
  BRow,
  BCol,
  BAlert,
  BLink,
} from "bootstrap-vue";
import ChangePasswordComponent from "./ChangePassword.vue";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewUserPlanCard from "./UserViewUserPlanCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BLink,

    // Local Components
    ChangePasswordComponent,
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    // InvoiceList,
  },
  
};
</script>

<style>
</style>
