<template>
  <div>
    <!-- success -->
    <b-alert variant="success" :show="show">
      <h4 class="alert-heading">Success</h4>
      <div class="alert-body">
        <span>{{ res.message || "Password updated successfully" }}</span>
      </div>
    </b-alert>
    <form @submit.prevent="validationForm">
    <b-row>
      <!-- Current password -->
      <b-col cols="12 mb-1">
        <label for="cpassword">Current Password</label>
        <input
          class="form-control"
          placeholder="Current Password"
          type="password"
          id="cpassword"
          v-model="currentpassword"
          required
        />
      </b-col>
      <!-- password -->
      <b-col cols="12 mb-1">
        <label for="newpassword">New Password</label>
        <input
          class="form-control"
          placeholder="New Password"
          type="password"
          id="newpassword"
          v-model="newpassword"
          required
        />
      </b-col>

      <!-- confirm password -->
      <b-col cols="12 mb-1">
        <label for="confiramPassword">Confirm Password</label>
        <input
          class="form-control"
          placeholder="Confirm Password"
          type="password"
          id="confiramPassword"
          v-model="passValue"
          required
        />
        
          <small style="color:red;" v-if="err"> {{err}} </small>
      </b-col>

      <!-- reset button -->
      <b-col cols="12 ">
        <button
          class="btn btn-primary mt-1"
          type="submit"
        >
          Submit
        </button>
      </b-col>
    </b-row>
    </form>
  </div>
</template>

<script>
import {
  BCardText,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BAlert,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BAlert,
    BPagination,
  },
  data() {
    return {
      err:"",
      show: false,
      passValue: "",
      username: "",
      newpassword: "",
      currentpassword: "",
      res: {},
    };
  },
  methods: {
    async validationForm() {
      const that = this;
      if (that.passValue == that.newpassword) {
        try {
          that.$http
            .put(`api/change-password/`, {
              old_password: that.currentpassword,
              new_password: that.newpassword,
            })
            .then((response) => {
              console.log(response);
              that.res = response.data;
              that.currentpassword = "";
              that.newpassword = "";
              that.passValue = "";
              that.show = true;
              this.err = ""
            })
            .catch((error) => {
              that.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: ` ${error}`,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: error,
                },
              });
              console.log(error);
            });
        } catch (err) {
          {
            that.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "CoffeeIcon",
                variant: "danger",
                text: err,
              },
            });
            console.log(err);
          }
        }
      }
      else{
        this.err = "New password and confirm password does not match"
      }
    },
  },
};
</script>